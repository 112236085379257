import fulfillmentsAllShipmentGridPage from './fulfillmentsAllShipmentGridPage';
import fulfillmentsBatchingPage from './fulfillmentsBatchingPage';
import fulfillmentsBatchingPrintPage from './fulfillmentsBatchingPrintPage';
import fulfillmentShipDetailsPage from './fulfillmentShipDetailsPage';
import fulfillmentShipPrintLogsPage from './fulfillmentShipPrintLogsPage';
import orderDetailsPage from './orderDetailsPage';
import preferredWarehousesPage from './preferredWarehousesPage';
import productDetailsPage from './productDetailsPage';
import productsGridPage from './productsGridPage';
import reportsPage from './reportsPage';
import shipmentDetailsPage from './shipmentDetailsPage';

export default {
    fulfillmentsAllShipmentGridPage,
    fulfillmentsBatchingPage,
    fulfillmentsBatchingPrintPage,
    fulfillmentShipDetailsPage,
    fulfillmentShipPrintLogsPage,
    orderDetailsPage,
    preferredWarehousesPage,
    productDetailsPage,
    productsGridPage,
    shipmentDetailsPage,
    reportsPage,
};
